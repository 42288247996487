import React from "react";
import PageHeaderComponent from "../../component/page-header.component";
import QRListComponent from "./qr-list.component";

const QRPage = () => {
    return (
        <>
            <PageHeaderComponent title={'QR list'}/>
            <QRListComponent />
        </>
    )
}

export default QRPage
