import React, {useState, useEffect} from 'react'
import {Layout} from 'antd';
import HeaderComponent from "./header.component";
import SideBarComponent from "./sidebar.component";
import {Colors} from "../ui-util/colors";

const {Content, Footer} = Layout;

const DashboardComponent = (props) => {
    const [show, setShow] = useState(false)
    const {content} = props

    useEffect(() => {
        if(!!(localStorage.getItem('TOKEN_AUTH'))){
            setShow(true)
        }
    }, [])

    return (
        <div>
        <Layout>
            <HeaderComponent/>
            <Layout className="site-layout-background" style={{ padding: '24px 0'}}>
                {!!show && <SideBarComponent/>}
                <Content style={{marginLeft: 25, marginRight: 25, padding: 20, backgroundColor: 'white', minHeight: 1000}}>
                    {content}
                </Content>
            </Layout>
            <Footer style={{backgroundColor: Colors.darkslategray, color: 'white', position: "sticky", bottom: "0"}}
                    className="d-flex justify-content-center align-items-center">
                IT Arena 2021
            </Footer>
        </Layout>
        </div>
    )
}

export default DashboardComponent
