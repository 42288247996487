import React from "react";
import {Modal} from "antd";

const EditFormModal = (props) => {
    const {modalVisibility, changeVisibility, content, title} = props
    return (
        <Modal
            visible={modalVisibility}
            title={title}
            onCancel={() => changeVisibility()}
            footer={null}
        >
            {content}
        </Modal>
    )
}

export default EditFormModal
