import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {DEFAULT_API_HEADER_WITH_TOKEN} from "./api.config";

const getReportListApi = (requestBody, params) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/report/`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER_WITH_TOKEN,
                params
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

export {
    getReportListApi
}
