import React, {useEffect, useState} from "react";
import {Button, Row, Table} from "antd";
import {getQrRequestedApi, updateQrApi, updateQrDetailApi} from "../../api/generator.api";
import StandardAlertModal from "../../component/standard-alert.modal";
import RangePickerComponent from "../../component/range-picker-date.component";
import moment from 'moment';
import {formatDateToReadable, logoutHandler} from "../../ui-util/general-variable";
import SelectBrandComponent from "../../component/select-brand.component";
import {Colors} from "../../ui-util/colors";
import EditFormModal from "../../component/edit-form.modal";
import QRFormComponent from "../create-qr/qr-form.component";

const QRListComponent = () => {
    const [columns] = useState([
        {
            title: 'Created At', dataIndex: 'createdAt', key: 'createdAt',
            render: (createdAt) => formatDateToReadable(createdAt)
        },
        {
            title: 'User Name', dataIndex: 'user', key: 'user',
            render: (user) => <div>{user?.name}</div>
        },
        {
            title: 'Brand Name', dataIndex: 'brand', key: 'brand',
            render: (brand) => <div>{brand?.brand_name}</div>
        },
        {
            title: 'Product Name', dataIndex: 'product', key: 'product',
            render: (product) => <div>{product?.product_name}</div>
        },
        {title: 'PO Code', dataIndex: 'po_code', key: 'po_code'},
        {title: 'Batch Number', dataIndex: 'batch', key: 'batch'},
        {title: 'Quantity', dataIndex: 'qty', key: 'qty'},
        {
            title: 'Expired Date', dataIndex: 'expired_at', key: 'expired_at',
            render: (expired_at) => <p>{expired_at ? expired_at : ''}</p>
        },
        {
            title: 'Status', dataIndex: 'status', key: 'status',
            render: (status) => <p>{status === 'PROCESSING' ? 'Sedang di Request' : 'Done'}</p>
        },
        {
            title: 'Action', key: 'edit',
            width: 230,
            render: (record) =>
                <Row justify="space-between">
                    <Button onClick={() => {
                        setQrDetail(record)
                        setEditQrModalVisibility(true)
                    }} style={{backgroundColor: Colors.mint}}>
                        Edit
                    </Button>
                    <Button onClick={() => downloadButtonHandler(record)} style={{backgroundColor: Colors.mint}}
                            disabled={record?.status === 'PROCESSING'}>
                        <a style={{textDecoration: 'none'}} href={record.file_path} target='_blank'>Download QR</a>
                    </Button>
                </Row>
        }
    ])

    const [dataSource, setDataSource] = useState([])
    const [size, setSize] = useState(10)
    const [page, setPage] = useState(1)
    const [totalData, setTotalData] = useState(0)
    const [loading, setLoading] = useState(false)
    const [modalVisibility, setModalVisibility] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [brand, setBrand] = useState('')
    const [editQrModalVisibility, setEditQrModalVisibility] = useState(false)
    const [loadingEdit, setLoadingEdit] = useState(false)
    const [qrDetail, setQrDetail] = useState({})

    useEffect(() => {
        getQRList()
    }, [size, page, brand])

    const getQRList = async () => {
        setLoading(true)
        let params = {
            page,
            size,
            startDate,
            endDate,
            status: '',
            sort: 'created_at-desc',
            brandId: brand
        }

        try {
            const result = await getQrRequestedApi(params)
            setDataSource(result.rows)
            setTotalData(result.count)
            setLoading(false)
        } catch (e) {
            console.log('Error getQRList', e)
            setLoading(false)
        }
    }

    const downloadButtonHandler = async (record) => {
        if (!record.file_path) {
            setModalVisibility(true)
        } else {
            try {
                await updateQrDetailApi(record.id)
                window.location.reload()
            } catch (e) {
                console.log('Error updateQrDetailApi', e)
            }
        }
    }

    const onChangePagination = (currentPage) => {
        setPage(currentPage)
    }

    const onSizeChange = (currentPage, pageSize) => {
        setPage(currentPage)
        setSize(pageSize)
    }

    const onChangeDatePicker = (value) => {
        if (!value) {
            setStartDate('')
            setEndDate('')
        } else {
            setStartDate(moment(value[0]).startOf('day').format())
            setEndDate(moment(value[1]).endOf('day').format())
        }
    }

    const onSelectBrand = (value) => {
        setPage(1)
        setBrand(value)
    }

    const onFinishEdit = (value) => {
        let expiredAtFormat = formatDateToReadable(value.expiredAt, 'YYYY-MM')
        updateQrApi(qrDetail.id, {expiredAt: String(expiredAtFormat)})
            .then(response => {
                if(response.data.code === 402){
                    logoutHandler()
                } else {
                    alert('Update Success!!')
                    setEditQrModalVisibility(false)
                    window.location.reload()
                }
            })
            .catch(err => {
                setEditQrModalVisibility(false)
                console.log(err)
            })
    }

    return (
        <div style={{marginLeft: 20}}>
            <Row style={{marginBottom: 20}} justify="space-between">
                <SelectBrandComponent onSelect={(value) => onSelectBrand(value)}/>
                <RangePickerComponent onChangeDatePicker={onChangeDatePicker}
                                      filterUserListByDate={() => getQRList()}/>
            </Row>
            <Table
                className="components-table-demo-nested"
                columns={columns}
                dataSource={dataSource}
                rowKey={'id'}
                loading={loading}
                pagination={{
                    pageSize: size,
                    pageSizeOptions: ['25', '50', '100'],
                    total: totalData,
                    onChange: onChangePagination,
                    current: page,
                    onShowSizeChange: onSizeChange,
                }}
            />
            <EditFormModal
                modalVisibility={editQrModalVisibility}
                changeVisibility={() => setEditQrModalVisibility(false)}
                title={"Edit brand"}
                content={
                    <QRFormComponent
                        wrapperCol={20}
                        labelCol={9}
                        qrDetail={qrDetail}
                        loading={loadingEdit}
                        onFinish={onFinishEdit}
                    />}
            />
            <StandardAlertModal
                modalVisibility={modalVisibility}
                title={"Generate QR"}
                changeVisibility={() => setModalVisibility(false)}
                bodyMessage={"Proses generate QR belum selesai. Pemberitahuan akan dikirimkan pada email anda setelah QR siap di download"}
                isShowCancelButton={false}
                onOkButtonHandler={() => setModalVisibility(false)}
            />
        </div>
    )
}

export default QRListComponent
