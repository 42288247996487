import React, {useState} from "react";
import PageHeaderComponent from "../../component/page-header.component";
import BrandFormComponent from "./brand-form.component";
import {createBrandApi} from "../../api/brand.api";
import {logoutHandler} from "../../ui-util/general-variable";

const FormData = require('form-data');

const CreateBrandPage = () => {
    const [loading, setLoading] = useState(false)

    const onFinish = async (values, fileImage) => {
        if(fileImage.length < 1){
            alert('Anda belum mencantumkan image')
        } else {
            setLoading(true)
            const form = new FormData();
            form.append('name', values.brand_name)
            form.append('code', values.brand_code)
            form.append('image', fileImage[0])
            try{
                const response = await createBrandApi(form)
                if(response.data.code === 402){
                    logoutHandler()
                } else {
                    setLoading(false)
                    alert('Brand berhasil disimpan!')
                    window.location.href = '/brand'
                }
            }catch (e) {
                setLoading(false)
                console.log('error createBrandApi', e);
                alert('Gagal menyimpan brand!')
            }
        }
    }
    return (
        <>
            <PageHeaderComponent title={'Create Brand'}/>
            <div style={{marginLeft: 20}}>
                <BrandFormComponent wrapperCol={10} labelCol={2} onFinish={onFinish} loading={loading}/>
            </div>
        </>
    )
}

export default CreateBrandPage
