import React from "react";
import PageHeaderComponent from "../../component/page-header.component";
import QRListComponent from "./qr-list.component";

const GenerateQrPage = () => {
    return (
        <>
            <PageHeaderComponent title={'Generate QR'}/>
            <QRListComponent />
        </>
    )
}

export default GenerateQrPage
