import React, {useEffect, useState} from "react";
import {Button, Form, Input, List} from "antd";
import {userSignInApi} from "../../api/user.api";

const UserFormComponent = () => {
    const formRef = React.createRef()
    const [loading, setLoading] = useState(false)
    const [userForm] = useState([
        {
            label: 'Nama',
            name: 'name',
            required: true,
            messageAlert: 'Please input name!',
            placeholder: 'Cth: Agnes Monica'
        }, {
            label: 'Email',
            name: 'email',
            required: true,
            messageAlert: 'Please input email!',
            placeholder: 'Cth: agnesmonica@gmail.com'
        }, {
            label: 'Role',
            name: 'user_role',
            required: true
        }
    ])

    useEffect(() => {
        formRef.current.setFieldsValue({user_role: 'ADMINISTRATOR'})
    }, [])

    const onFinish = async (values) => {
        try {
            await userSignInApi(values)
            alert('User berhasil di buat!')
            window.location.reload()
        }catch (e) {
            console.log('Error userSignInApi', e)
        }
    }
    return (
        <Form ref={formRef} name="control-hooks" onFinish={onFinish} labelCol={{span: 2}}
              wrapperCol={{span: 10}}>
            <List
                dataSource={userForm}
                renderItem={(item, index) => (
                    <Form.Item
                        key={index.toString()}
                        label={item.label}
                        name={item.name}
                        rules={[{
                            required: item.required,
                            message: item.messageAlert,
                        }]}
                    >
                        <Input disabled={item.name === 'user_role'} placeholder={item.placeholder}/>
                    </Form.Item>
                )}
            />
            <Form.Item
                wrapperCol={{
                    span: 3,
                    offset: 2,
                }}
            >
                <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default UserFormComponent
