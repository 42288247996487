import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {DEFAULT_API_HEADER} from "./api.config";

const token = localStorage.getItem('TOKEN_AUTH');

const createBrandApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/brand/create`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

const getBrandListApi = () => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/v1/brand/`,
            {headers: DEFAULT_API_HEADER},
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

const updateBrandDetailApi = (id, requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/brand/update/${id}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': token
                }
            },
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

export {
    createBrandApi,
    getBrandListApi,
    updateBrandDetailApi
}
