import React, {useState, useEffect} from 'react'
import PageHeaderComponent from "../../component/page-header.component";
import ImageListComponent from "../../component/image-list.component";
import {Button, Image} from "antd";
import EditFormModal from "../../component/edit-form.modal";
import BrandFormComponent from "../create-brand/brand-form.component";
import StandardAlertModal from "../../component/standard-alert.modal";
import {getBrandListApi, updateBrandDetailApi} from "../../api/brand.api";
import {logoutHandler} from "../../ui-util/general-variable";

const BrandPage = () => {
    const [editBrandModalVisibility, setEditBrandModalVisibility] = useState(false)
    const [deleteBrandModalVisibility, setDeleteBrandModalVisibility] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [brandDetail, setBrandDetail] = useState({})
    const [reload, setReload] = useState(false)
    const [columns] = useState([
        {title: 'Brand Name', dataIndex: 'brand_name', key: 'brand_name'},
        {title: 'Brand Code', dataIndex: 'brand_code', key: 'brand_code'},
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image) => <Image src={image} width={100} height={100}/>
        }, {
            title: 'Action', key: 'edit',
            render: (record) =>
                <>
                    <Button style={{marginRight: 10}}
                            onClick={() => {
                                setEditBrandModalVisibility(true)
                                setBrandDetail(record)
                            }}>Edit</Button>
                    <Button
                        onClick={() => {
                            setDeleteBrandModalVisibility(true)
                            setBrandDetail(record)
                        }}>Delete</Button>
                </>
        }
    ])

    useEffect(() => {
        const getBrandList = async () => {
            setLoadingTable(true)
            try {
                const result = await getBrandListApi()
                setDataSource(result)
                setLoadingTable(false)
            } catch (e) {
                console.log('Error getBrandListApi', e)
                setLoadingTable(false)
            }
        }

        getBrandList()

    }, [reload])

    const onFinish = (values, fileImage) => {
        if (fileImage.length < 1 && !!values.image.file) {
            alert('Anda belum mencantumkan image')
        } else {
            setLoading(true)
            const form = new FormData();
            form.append('image', fileImage[0])
            form.append('name', values.brand_name)
            form.append('code', values.brand_code)
            updateBrandDetail(form, 'update')
        }
    }

    const updateBrandDetail = async (form, slug) => {
        try {
            const response = await updateBrandDetailApi(brandDetail.id, form)
            if(response.data.code === 402){
                logoutHandler()
            } else {
                setReload(!reload)
                setLoading(false)
                if(slug === 'update'){
                    setEditBrandModalVisibility(false)
                } else {
                    setLoadingDelete(false)
                    setDeleteBrandModalVisibility(false)
                }
                let alertMessage = slug === 'update' ? 'Brand berhasil di ubah!' : 'Berhasil menghapus brand!'
                alert(alertMessage)
            }
        } catch (e) {
            console.log('Error updateBrandDetailApi', slug, e)
            alert('Gagal melakukan update brand')
        }
    }

    const onOkButtonHandler = () => {
        const form = new FormData();
        form.append('valid', false)
        updateBrandDetail(form, 'delete')
    }

    return (
        <>
            <PageHeaderComponent title={'Brand List'}/>
            <ImageListComponent columns={columns} dataSource={dataSource} loading={loadingTable} pagination={true}/>
            <EditFormModal
                modalVisibility={editBrandModalVisibility}
                changeVisibility={() => setEditBrandModalVisibility(false)}
                title={"Edit brand"}
                content={
                    <BrandFormComponent
                        wrapperCol={20}
                        labelCol={5}
                        brandDetail={brandDetail}
                        loading={loading}
                        onFinish={onFinish}
                    />}
            />
            <StandardAlertModal
                modalVisibility={deleteBrandModalVisibility}
                title={"Delete brand"}
                changeVisibility={() => setDeleteBrandModalVisibility(false)}
                bodyMessage={"Apakah anda yakin ingin menghapus brand ini?"}
                onOkButtonHandler={onOkButtonHandler}
                loading={loadingDelete}
            />
        </>
    )
}

export default BrandPage
