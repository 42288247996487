import React, {useEffect, useState} from "react";
import {Row, Select} from "antd";
import {getBrandListApi} from "../api/brand.api";

const {Option} = Select

const SelectBrandComponent = (props) => {
    const {onSelect} = props
    const [brandList, setBrandList] = useState([])
    useEffect(() => {
        const getBrandList = async () => {
            try {
                const result = await getBrandListApi()
                let resultList = [{id: '', brand_name: 'ALL'}].concat(result)
                setBrandList(resultList)
            } catch (e) {
                console.log('Error getBrandList qr list component', e)
            }
        }

        getBrandList()
    }, [])

    return (
        <Row justify="center">
            <div style={{marginRight: 20}}>Brand :</div>
            <Select
                style={{minWidth: 200}}
                onSelect={onSelect}
            >
                {
                    brandList.map((item, index) => (
                        <Option value={item.id}
                                key={index.toString()}>{item.brand_name}</Option>
                    ))
                }
            </Select>
        </Row>
    )
}

export default SelectBrandComponent
