import React, {useEffect, useState} from "react";
import {Button, DatePicker, Form, Input, List, Select} from "antd";
import _ from "lodash";
import moment from 'moment';
import {getBrandListApi} from "../../api/brand.api";
import {getProductListApi} from "../../api/product.api";

const {Option} = Select;

const QRFormComponent = (props) => {
    const formRef = React.createRef()
    const {wrapperCol, labelCol, onFinish, loading, qrDetail} = props
    const [brandId, setBrandId] = useState(null)
    const [qrForm] = useState([
        {
            label: 'Brand',
            name: 'brandId',
            type: 'select',
            required: true,
            messageAlert: 'Please select brand!',
            placeholder: 'Cth: Lacoco'
        }, {
            label: 'Product',
            name: 'productId',
            type: 'select',
            required: true,
            messageAlert: 'Please select product!',
            placeholder: 'Cth: Dark Spot Essence'
        }, {
            label: 'PO Number',
            name: 'poCode',
            type: 'input',
            required: true,
            messageAlert: 'Please input PO number!',
            placeholder: 'Cth: 10'
        }, {
            label: 'Urutan Pembuatan QR',
            name: 'batch',
            type: 'input',
            required: true,
            messageAlert: 'Please input "Urutan Pembuatan QR"!',
            placeholder: 'Cth: 001'
        }, {
            label: 'Quantity',
            name: 'total',
            type: 'input',
            required: true,
            messageAlert: 'Please input quantity!',
            placeholder: 'Cth: 10000'
        }, {
            label: 'Expired Date',
            name: 'expiredAt',
            type: 'date',
            required: true,
            messageAlert: 'Please input expired date!',
            placeholder: 'Cth: 10-11-2023'
        }
    ])
    const [brandList, setBrandList] = useState([])
    const [productList, setProductList] = useState([])

    useEffect(() => {
        Promise.all([getBrandListApi(), getProductListApi({name: ''})])
            .then(result => {
                setBrandList(result[0])
                setProductList(result[1])
            })
            .catch(err => {
                console.log('Error getBrandAndProductList', err)
            })
    }, [])

    useEffect(() => {
        if (!!qrDetail) {
            formRef.current.setFieldsValue({
                ...qrDetail, ...{
                    total: qrDetail.qty,
                    poCode: qrDetail.po_code,
                    expiredAt: qrDetail?.expired_at ? moment(qrDetail?.expired_at, 'MM-YYYY') : ''
                }
            })
        }
    }, [qrDetail])

    const selectHandler = (key, item) => {
        if (item.name === 'brandId') {
            setBrandId(key)
        }
    }

    let productListFiltered = _.filter(productList, ['brand_id', brandId])
    let productListNew = !!brandId ? productListFiltered : productList

    return (
        <Form ref={formRef} name="control-hooks" onFinish={(value) => onFinish(value)}
              labelCol={{span: labelCol}}
              wrapperCol={{span: wrapperCol}}>
            <List
                dataSource={qrForm}
                renderItem={(item, index) => {
                    switch (item.type) {
                        case 'select':
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={item.label}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Select
                                        style={{width: '100%'}}
                                        placeholder={item.placeholder}
                                        disabled={!!qrDetail || item.name === 'productId' && !brandId ? true : false}
                                        onChange={(value) => selectHandler(value, item)}
                                    >
                                        {
                                            item.name === 'brandId' &&
                                            brandList.map((item, index) => (
                                                <Option value={item.id}
                                                        key={index.toString()}>{item.brand_name}</Option>
                                            ))
                                        }
                                        {
                                            item.name === 'productId' &&
                                            productListNew.map((item, index) => (
                                                <Option value={item.id}
                                                        key={index.toString()}>{item.product_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            )
                        case 'date':
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={item.label}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <DatePicker picker="month" format={'MM-YYYY'}/>
                                </Form.Item>
                            )
                        default:
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={item.label}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Input disabled={!!qrDetail} placeholder={item.placeholder}/>
                                </Form.Item>
                            )
                    }
                }}
            />
            <Form.Item
                wrapperCol={{
                    span: 2,
                    offset: labelCol,
                }}
            >
                <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default QRFormComponent
