import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {DEFAULT_API_HEADER, DEFAULT_API_HEADER_FORM_DATA_WITH_TOKEN} from "./api.config";

const getProductListApi = (requestBody, params) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/product/`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER,
                params
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

const createProductApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/product/create`,
            requestBody,
            {headers: DEFAULT_API_HEADER_FORM_DATA_WITH_TOKEN},
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

const updateProductDetailApi = (id, requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/product/update/${id}`,
            requestBody,
            {headers: DEFAULT_API_HEADER_FORM_DATA_WITH_TOKEN},
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

export {
    getProductListApi,
    createProductApi,
    updateProductDetailApi
}
