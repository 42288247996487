import React from "react";
import {Switch} from "react-router-dom";
import Route from './route'
import BrandPage from "../pages/brand";
import CreateBrandPage from "../pages/create-brand";
import LandingPage from "../pages/landing";
import CreateProductPage from "../pages/create-product";
import ProductPage from "../pages/product";
import CreateQR from "../pages/create-qr";
import QRPage from "../pages/qr ";
import TrackingPage from "../pages/tracking";
import ReportPage from "../pages/report";
import UserPage from "../pages/user";
import GenerateQrPage from "../pages/generate-qr";

export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={LandingPage}/>

            <Route path="/brand" exact component={BrandPage} isPrivate/>
            <Route path="/brand/create" exact component={CreateBrandPage} isPrivate/>

            <Route path="/product" exact component={ProductPage} isPrivate/>
            <Route path="/product/create" exact component={CreateProductPage} isPrivate/>

            <Route path="/qr" exact component={QRPage} isPrivate/>
            <Route path="/qr/request" exact component={CreateQR} isPrivate/>
            <Route path="/qr/generate-qr" exact component={GenerateQrPage} isPrivate/>

            <Route path="/tracking" exact component={TrackingPage} isPrivate/>

            <Route path="/report" exact component={ReportPage} isPrivate/>

            <Route path="/setting/user" exact component={UserPage} isPrivate/>
        </Switch>
    )
}
