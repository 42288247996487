import React, {useState} from "react";
import PageHeaderComponent from "../../component/page-header.component";
import ProductFormComponent from "./product-form.component";
import {createProductApi} from "../../api/product.api";
import {logoutHandler} from "../../ui-util/general-variable";

const CreateProductPage = () => {
    const [loading, setLoading] = useState(false)

    const onFinish = async (values, fileImage) => {
        if (fileImage.length < 1 && !!values.image.file) {
            alert('Anda belum mencantumkan image')
        } else {
            setLoading(true)
            const form = new FormData();
            form.append('image', fileImage[0])
            form.append('name', values.name)
            form.append('code', values.code)
            form.append('brand_id', values.brand_id)

            try {
                const response = await createProductApi(form)
                if(response.data.code === 402){
                    logoutHandler()
                } else {
                    setLoading(false)
                    alert('Product berhasil disimpan!')
                    window.location.href = '/product'
                }

            }catch (e) {
                setLoading(false)
                console.log('Error createProductApi', e)
            }
        }
    }
    return (
        <>
            <PageHeaderComponent title={'Create product'}/>
            <div style={{marginLeft: 20}}>
                <ProductFormComponent
                    wrapperCol={10}
                    labelCol={1}
                    onFinish={onFinish}
                    loading={loading}
                    slug={'add'}/>
            </div>
        </>
    )
}

export default CreateProductPage
