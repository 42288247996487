import React, {useState} from "react";
import PageHeaderComponent from "../../component/page-header.component";
import QRFormComponent from "./qr-form.component";
import {requestQrApi} from "../../api/generator.api";
import StandardAlertModal from "../../component/standard-alert.modal";
import {formatDateToReadable, logoutHandler} from "../../ui-util/general-variable";

const CreateQR = () => {
    const [loading, setLoading] = useState(false)
    const [modalVisibility, setModalVisibility] = useState(false)

    const onFinish = async (values) => {
        values.total = Number(values.total)
        values.batch = Number(values.batch)
        values.expiredAt = formatDateToReadable(values.expiredAt, 'YYYY-MM')

        if(values.total > 15000){
            alert('Jumlah QR yang  dapat dibuat maksimal 15.000')
        } else {
            setLoading(true)
            try {
                const result = await requestQrApi(values)
                if(result.data.code === 402){
                    logoutHandler()
                } else {
                    setLoading(false)
                    setModalVisibility(true)
                }
            } catch (e) {
                console.log('Error ', e)
                setLoading(false)
            }
        }
    }

    const onOkButtonHandler = () => {
        setModalVisibility(false)
        window.location.href = '/qr'
    }

    return (
        <>
            <PageHeaderComponent title={'Request QR'}/>
            <QRFormComponent wrapperCol={10} labelCol={4} onFinish={onFinish} loading={loading}/>
            <StandardAlertModal
                modalVisibility={modalVisibility}
                title={"Request QR"}
                changeVisibility={() => setModalVisibility(false)}
                bodyMessage={"Mohon tunggu sampai QR yang Anda request selesai dibuat. Pemberitahuan QR siap di download akan dikirimkan ke email Anda."}
                isShowCancelButton={false}
                onOkButtonHandler={onOkButtonHandler}
            />
        </>
    )
}

export default CreateQR
