import React from "react";
import {Table} from "antd";

const ImageListComponent = (props) => {
    const {columns, dataSource, loading, pagination} = props
    return (
        <div style={{marginLeft: 20}}>
            <Table
                className="components-table-demo-nested"
                columns={columns}
                dataSource={dataSource}
                pagination={pagination}
                rowKey={'id'}
                loading={loading}
            />
        </div>
    )
}

export default ImageListComponent
