import React from "react";
import {Button, Modal} from "antd";

const StandardAlertModal = (props) => {
    const {modalVisibility, changeVisibility, title, bodyMessage, onOkButtonHandler, loading, isShowCancelButton} = props
    return (
        <Modal
            visible={modalVisibility}
            title={title}
            onCancel={() => changeVisibility(false)}
            onOk={() => onOkButtonHandler()}
            footer={
                !isShowCancelButton ?
                    [
                        <Button key="submit" type="primary" loading={loading} onClick={() => onOkButtonHandler()}>
                            Ok
                        </Button>,
                    ] :
                    [
                        <Button key="back" onClick={() => changeVisibility(false)}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={() => onOkButtonHandler()}>
                            Ok
                        </Button>,
                    ]
            }
        >
            <p>{bodyMessage}</p>
        </Modal>
    )
}

export default StandardAlertModal
