import React, {useEffect, useState} from "react";
import {Button, Form, Image, Input, List, Row, Select, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {getBrandListApi} from "../../api/brand.api";
import {randomString} from "../../ui-util/general-variable";

const {Option} = Select;

const ProductFormComponent = (props) => {
    const formRef = React.createRef()
    const {wrapperCol, labelCol, onFinish, loading, productDetail, slug} = props
    const [fileImageList, setFileImageList] = useState([])
    const [showImage, setShowImage] = useState(false)
    const [productForm] = useState([
        {
            label: 'Brand',
            name: 'brand_id',
            type: 'select',
            required: true,
            messageAlert: 'Please select brand!',
            placeholder: 'Cth: Lacoco'
        }, {
            label: 'Name',
            name: 'name',
            type: 'input',
            required: true,
            messageAlert: 'Please input product name!',
            placeholder: 'Cth: Dark Spot Essence'
        }, {
            label: 'Code',
            name: 'code',
            type: 'input',
            required: true,
            messageAlert: 'Please input product code!',
            placeholder: 'Cth: DSE'
        }, {
            label: 'Image',
            name: 'image',
            type: 'image',
            required: true,
            messageAlert: 'Please upload product image!',
        },
    ])
    const [brandList, setBrandList] = useState([])

    useEffect(() => {
        if (!!productDetail) {
            setShowImage(true)
            productDetail.brand_id = productDetail.brand.brand_name
            productDetail.name = productDetail.product_name
            productDetail.code = productDetail.product_code
            formRef.current.setFieldsValue(productDetail)
        }
        if(slug === 'add'){
            getBrandList()
            formRef.current.setFieldsValue({code: randomString(3)})
        }

    }, [productDetail])

    const getBrandList = async () => {
        try {
            const result = await getBrandListApi()
            setBrandList(result)
        } catch (e) {
            console.log('Error getBrandListApi', e)
        }
    }

    const beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            setFileImageList([fileImage])
            setShowImage(false)
        }
        return false
    }

    return (
        <Form ref={formRef} name="control-hooks" onFinish={(value) => onFinish(value, fileImageList)}
              labelCol={{span: labelCol}}
              wrapperCol={{span: wrapperCol}}>
            <List
                dataSource={productForm}
                renderItem={(item, index) => {
                    switch (item.type) {
                        case 'image':
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={item.label}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Upload
                                        fileList={fileImageList}
                                        onRemove={() => setFileImageList([])}
                                        beforeUpload={beforeUploadImage}
                                    >
                                        {
                                            showImage &&
                                            <Image
                                                src={productDetail.image}
                                                width={100}
                                            />
                                        }
                                        <Row
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginTop: 10
                                            }}>
                                            <Button icon={<UploadOutlined/>}>
                                                Upload
                                            </Button>
                                            <p style={{marginLeft: 10}}>Ukuran gambar maksimal 2MB</p>
                                        </Row>
                                    </Upload>
                                </Form.Item>

                            )
                        case 'select':
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={item.label}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Select
                                        style={{width: '100%'}}
                                        placeholder={item.placeholder}
                                        disabled={slug === 'edit'}
                                    >
                                        {
                                            brandList.map((item, index) => (
                                                <Option value={item.id}
                                                        key={index.toString()}>{item.brand_name}</Option>
                                            ))}
                                    </Select>

                                </Form.Item>
                            )
                        default:
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={item.label}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Input disabled={item.name === 'code'} placeholder={item.placeholder}/>
                                </Form.Item>
                            )
                    }
                }}
            />
            <Form.Item
                wrapperCol={{
                    span: 2,
                    offset: labelCol,
                }}
            >
                <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default ProductFormComponent
