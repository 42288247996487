import React from 'react'
import './App.css';
import {BrowserRouter} from 'react-router-dom';
import DashboardComponent from "./component/dashboard.component";
import Routes from "./routes";

function App() {
  return (
    <BrowserRouter>
      <DashboardComponent content={<Routes />}/>
    </BrowserRouter>
  );
}

export default App;
