import React from "react";
import PageHeaderComponent from "../../component/page-header.component";
import ReportListComponent from "./report-list.component";

const ReportPage = () => {
    return (
        <>
            <PageHeaderComponent title={'Report List'}/>
            <ReportListComponent />
        </>
    )
}

export default ReportPage
