import moment from 'moment';

const cloudFunctionBaseUrl = () => {
    return process.env.REACT_APP_BASE_URL
}

const formatDateToReadable = (date, format="DD-MM-YYYY") => {
    return moment(date).format(format)
}

const clientId = () => {
    let env = process.env.REACT_APP_ENVIRONTMENT
    switch (env){
        case 'PRODUCTION':
            return '31594243292-malft88cq86k0admgir5e8j0nl0mlsff.apps.googleusercontent.com'
        case 'STAGING':
            return '31594243292-0notartjt58n72gc3obddigcgsvooqa8.apps.googleusercontent.com'
        default:
            return '821549964776-pengiciuuf4q19iv1hskjun6n4eb728a.apps.googleusercontent.com'

    }
}

const randomString = (n) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for ( let i = 0; i < n; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

const logoutHandler = (code) => {
    localStorage.removeItem('TOKEN_AUTH')
    window.location.reload()
}

export {
    cloudFunctionBaseUrl,
    clientId,
    formatDateToReadable,
    randomString,
    logoutHandler
}
