import React from "react";
import {Input} from "antd";

const {Search} = Input;

const SearchComponent = (props) => {
    const {placeholder, onSearch} = props
    return (
        <Search
            placeholder={placeholder}
            onSearch={onSearch}
            style={{width: 300}}
            enterButton="Search"
        />
    )
}

export default SearchComponent
