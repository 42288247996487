import React, {useEffect, useState} from "react";
import {Image, Row, Table} from "antd";
import {getReportListApi} from "../../api/report.api";
import {formatDateToReadable} from "../../ui-util/general-variable";
import RangePickerComponent from "../../component/range-picker-date.component";
import moment from "moment";

const ReportListComponent = () => {
    const [loading, setLoading] = useState(false)
    const [columns] = useState([
        {
            title: 'Report Date', dataIndex: 'createdAt', key: 'reportDate',
            render: (createdAt) => formatDateToReadable(createdAt)
        },
        {title: 'Name', dataIndex: 'name', key: 'name'},
        {title: 'Email', dataIndex: 'email', key: 'email'},
        {title: 'Phone No.', dataIndex: 'phone', key: 'phone'},
        {title: 'QR Code Id', dataIndex: 'qr_serial', key: 'qr_serial'},
        {
            title: 'Description',
            width: 300,
            dataIndex: 'description',
            key: 'description',
            render: (description) => <div style={{wordWrap: 'break-word', wordBreak: 'break-word'}}>
                {description}
            </div>
        },
        {
            title: 'Expired Image',
            dataIndex: 'expired_image',
            key: 'expired_image',
            render: (image) => <Image src={image} width={100} height={100}/>
        },
        {
            title: 'QR Image',
            dataIndex: 'qr_image',
            key: 'qr_image',
            render: (image) => <Image src={image} width={100} height={100}/>
        },
    ])
    const [dataSource, setDataSource] = useState([])
    const [size, setSize] = useState(10)
    const [page, setPage] = useState(1)
    const [totalData, setTotalData] = useState(0)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    useEffect(() => {
        getReportList()
    }, [size, page])

    const getReportList = async () => {
        setLoading(true)
        let requestBody = {
            startDate,
            endDate
        }
        let params = {
            page: page - 1,
            size
        }

        try {
            const result = await getReportListApi(requestBody, params)
            setDataSource(result.data)
            setTotalData(result.totalDatas)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log('Error getReportList', e)
        }
    }

    const onChangePagination = (currentPage) => {
        setPage(currentPage)
    }

    const onSizeChange = (currentPage, pageSize) => {
        setPage(currentPage)
        setSize(pageSize)
    }

    const onChangeDatePicker = (value) => {
        if(!value){
            setStartDate('')
            setEndDate('')
        } else {
            setStartDate(moment(value[0]).format('YYYY-MM-DD'))
            setEndDate(moment(value[1]).format('YYYY-MM-DD'))
        }
    }

    return (
        <div style={{marginLeft: 20}}>
            <Row style={{marginBottom: 20}} justify="space-between">
                <RangePickerComponent onChangeDatePicker={onChangeDatePicker}
                                      filterUserListByDate={() => getReportList()}/>
            </Row>
            <Table
                className="components-table-demo-nested"
                columns={columns}
                dataSource={dataSource}
                rowKey={'id'}
                loading={loading}
                pagination={{
                    pageSize: size,
                    pageSizeOptions: ['25', '50', '100'],
                    total: totalData,
                    onChange: onChangePagination,
                    current: page,
                    onShowSizeChange: onSizeChange,
                }}
            />
        </div>
    )
}

export default ReportListComponent
