import React, {useState} from "react";
import {Input, Table} from 'antd';
import PageHeaderComponent from "../../component/page-header.component";
import {getHistoryQRApi} from "../../api/validator.api";
import {formatDateToReadable} from "../../ui-util/general-variable";

const {Search} = Input;

const TrackingPage = () => {
    const [historyList, setHistoryList] = useState([])
    const [resultMessage, setResultMessage] = useState(null)
    const [columns] = useState([
        {
            title: 'Tanggal Scan', dataIndex: 'createdAt', key: 'createdAt',
            render: (createdAt) => formatDateToReadable(createdAt)
        },
        {title: 'QR Serial', dataIndex: 'qr_serial', key: 'qr_serial'},
        {title: 'IP Address', dataIndex: 'ip_address', key: 'ip_address'}
    ])

    const searchQRCode = async (value) => {
        try {
            const result = await getHistoryQRApi({qr_serial: value})
            if(result.length > 0){
                setHistoryList(result)
            } else {
                setResultMessage('Hasil Pencarian Tidak Ditemukan')
            }

        }catch (e) {
            console.log('Error searchQRCode', e)
            setResultMessage('Kesalahan Jaringan, mohon coba kembali')
        }
    }

    console.log(historyList)

    return (
        <>
            <PageHeaderComponent title={'QR Tracking'}/>
            <div style={{marginLeft: 20, marbinBottom: 20}}>
                <Search placeholder="input search text" enterButton="Search" size="large" onSearch={(value) => searchQRCode(value)}/>
            </div>
            {
                historyList.length > 0 &&
                <Table
                    className="components-table-demo-nested"
                    columns={columns}
                    dataSource={historyList}
                    pagination={false}
                    rowKey={'id'}
                    style={{marginLeft: 20, marginTop: 20}}
                />
            }
            <div className="d-flex justify-content-center align-items-center" style={{ marginTop: 100}}>{!!resultMessage && resultMessage}</div>
        </>
    )
}

export default TrackingPage
