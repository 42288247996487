const token = localStorage.getItem('TOKEN_AUTH');

const DEFAULT_API_HEADER = {
    'Accept': 'application/json',
    'Content-type': 'application/json',
};

const DEFAULT_API_HEADER_WITH_TOKEN = {
    'Accept': 'application/json',
    'Content-type': 'application/json',
    'Authorization': token
}

const DEFAULT_API_HEADER_FORM_DATA_WITH_TOKEN = {
    'Accept': 'application/json',
    'content-type': 'multipart/form-data',
    'Authorization': token
};

export {
    DEFAULT_API_HEADER,
    DEFAULT_API_HEADER_WITH_TOKEN,
    DEFAULT_API_HEADER_FORM_DATA_WITH_TOKEN
}
