import React, {useEffect, useState} from "react";
import {Button, Row, Table} from "antd";
import {getQrRequestedApi} from "../../api/generator.api";
import StandardAlertModal from "../../component/standard-alert.modal";
import RangePickerComponent from "../../component/range-picker-date.component";
import moment from 'moment';
import {formatDateToReadable} from "../../ui-util/general-variable";
import SelectBrandComponent from "../../component/select-brand.component";
import {Colors} from "../../ui-util/colors";

const QRListComponent = () => {
    const [columns] = useState([
        {
            title: 'Created At', dataIndex: 'createdAt', key: 'createdAt',
            render: (createdAt) => formatDateToReadable(createdAt)
        },
        {
            title: 'User Name', dataIndex: 'user', key: 'user',
            render: (user) => <div>{user?.name}</div>
        },
        {
            title: 'Brand Name', dataIndex: 'brand', key: 'brand',
            render: (brand) => <div>{brand?.brand_name}</div>
        },
        {
            title: 'Product Name', dataIndex: 'product', key: 'product',
            render: (product) => <div>{product?.product_name}</div>
        },
        {title: 'PO Code', dataIndex: 'po_code', key: 'po_code'},
        {title: 'Batch Number', dataIndex: 'batch', key: 'batch'},
        {title: 'Quantity', dataIndex: 'qty', key: 'qty'},
        {
            title: 'Status', dataIndex: 'status', key: 'status',
            render: (status) => <p>{status === 'PROCESSING' ? 'Sedang di Request' : 'Done'}</p>
        },
        {
            title: 'Action', key: 'edit',
            render: (record) =>
                <Button onClick={() => generateQrButtonHandler(record)} style={{backgroundColor: Colors.mint}}>
                    <a style={{textDecoration: 'none'}}>Generate QR</a>
                </Button>
        }
    ])

    const [dataSource, setDataSource] = useState([])
    const [size, setSize] = useState(10)
    const [page, setPage] = useState(1)
    const [totalData, setTotalData] = useState(0)
    const [loading, setLoading] = useState(false)
    const [modalVisibility, setModalVisibility] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [brand, setBrand] = useState('')
    const [id, setId] = useState(null)

    useEffect(() => {
        getQRList()
    }, [size, page, brand])

    const getQRList = async () => {
        setLoading(true)
        let params = {
            page,
            size,
            startDate,
            endDate,
            status: 'PROCESSING',
            sort: 'created_at-desc',
            brandId: brand
        }

        try {
            const result = await getQrRequestedApi(params)
            setDataSource(result.rows)
            setTotalData(result.count)
            setLoading(false)
        } catch (e) {
            console.log('Error getQRList', e)
            setLoading(false)
        }
    }

    const generateQrButtonHandler = async (record) => {
        setId(record?.id)
        setModalVisibility(true)
    }

    const onChangePagination = (currentPage) => {
        setPage(currentPage)
    }

    const onSizeChange = (currentPage, pageSize) => {
        setPage(currentPage)
        setSize(pageSize)
    }

    const onChangeDatePicker = (value) => {
        if (!value) {
            setStartDate('')
            setEndDate('')
        } else {
            setStartDate(moment(value[0]).startOf('day').format())
            setEndDate(moment(value[1]).endOf('day').format())
        }
    }

    const onSelectBrand = (value) => {
        setPage(1)
        setBrand(value)
    }

    return (
        <div style={{marginLeft: 20}}>
            <Row style={{marginBottom: 20}} justify="space-between">
                <SelectBrandComponent onSelect={(value) => onSelectBrand(value)}/>
                <RangePickerComponent onChangeDatePicker={onChangeDatePicker}
                                      filterUserListByDate={() => getQRList()}/>
            </Row>
            <Table
                className="components-table-demo-nested"
                columns={columns}
                dataSource={dataSource}
                rowKey={'id'}
                loading={loading}
                pagination={{
                    pageSize: size,
                    pageSizeOptions: ['25', '50', '100'],
                    total: totalData,
                    onChange: onChangePagination,
                    current: page,
                    onShowSizeChange: onSizeChange,
                }}
            />
            <StandardAlertModal
                modalVisibility={modalVisibility}
                title={"Generate QR"}
                changeVisibility={() => setModalVisibility(false)}
                bodyMessage={`Generate QR here http://localhost:8080/api/v2/generator/${id}`}
                isShowCancelButton={false}
                onOkButtonHandler={() => setModalVisibility(false)}
            />
        </div>
    )
}

export default QRListComponent
