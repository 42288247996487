import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {DEFAULT_API_HEADER} from "./api.config";

const userSignInApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/user/signin`,
            requestBody,
            {headers: DEFAULT_API_HEADER},
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

export {
    userSignInApi
}
