import React, {useEffect, useState} from 'react'
import {Layout, Menu} from "antd";
import {Link} from "react-router-dom";

const {Sider} = Layout;
const {SubMenu} = Menu;

const SideBarComponent = () => {
    const [path, setPath] = useState('/brand/')
    const [pathName, setPathName] = useState('brand')
    const [listMenu] = useState([
        {
            key: 'brand',
            title: 'Brand',
            subMenu: [
                {
                    key: '/brand/create',
                    params: 'create',
                    label: 'Create Brand'
                }, {
                    key: '/brand/',
                    params: '',
                    label: 'Brand List'
                }
            ]
        }, {
            key: 'product',
            title: 'Product',
            subMenu: [
                {
                    key: '/product/create',
                    params: 'create',
                    label: 'Create Product'
                }, {
                    key: '/product',
                    params: '',
                    label: 'Product List'
                }
            ]
        }, {
            key: 'qr',
            title: 'Generator',
            subMenu: [
                {
                    key: '/qr/request',
                    params: 'request',
                    label: 'Request QR'
                }, {
                    key: '/qr',
                    params: '',
                    label: 'QR List'
                }, {
                    key: '/generate-qr',
                    params: 'generate-qr',
                    label: 'Generate QR'
                }
            ]
        },
        // {
        //     key: 'tracking',
        //     title: 'Tracking',
        //     subMenu: [
        //         {
        //             key: '/tracking',
        //             params: '',
        //             label: 'QR Tracking'
        //         }
        //     ]
        // },
        {
            key: 'report',
            title: 'Report',
            subMenu: [
                {
                    key: '/report',
                    params: '',
                    label: 'Report List'
                }
            ]
        }, {
            key: 'setting',
            title: 'Setting',
            subMenu: [
                {
                    key: '/setting/user',
                    params: 'user',
                    label: 'User'
                }
            ]
        }
    ])

    useEffect(() => {
        const pathRaw = window.location.pathname.split('/')
        const path = window.location.pathname
        setPath(path)
        setPathName(pathRaw[1])
    }, [])

    const onClickMenu = (item) => {
        setPath(item.key)
    }

    return (
        <Sider style={{backgroundColor: 'white'}}>
            <Menu mode="inline" defaultOpenKeys={[pathName]} selectedKeys={[path]} onClick={onClickMenu}>
                {listMenu.map((menu) => (
                    <SubMenu key={menu.key} title={menu.title} multiple={true}>
                        {menu.subMenu.map((submenu, index) => (
                            <Menu.Item key={submenu.key}>
                                <Link to={'/' + menu.key + '/' + submenu.params} style={{textDecoration: 'none'}} >
                                    {submenu.label}
                                </Link>
                            </Menu.Item>
                        ))}
                    </SubMenu>
                ))}
            </Menu>
        </Sider>
    )
}

export default SideBarComponent
