import React from "react";
import {Button, Layout, Menu} from 'antd';
import {GoogleLogout} from "react-google-login";
import {Colors} from "../ui-util/colors";
import {clientId} from "../ui-util/general-variable";

const {Header} = Layout;

const HeaderComponent = () => {
    const token = localStorage.getItem('TOKEN_AUTH');

    const logoutHandler = () => {
        localStorage.removeItem('TOKEN_AUTH')
        window.location.reload()
    }

    return (
        <Header className="d-flex align-items-center justify-content-between header"
                style={{backgroundColor: Colors.darkslategray}}>
            <h3 style={{color: 'white'}}>QR Generator</h3>
            {
                !!token &&
                <GoogleLogout
                    render={renderProps => <Button
                        style={{backgroundColor: 'transparent', borderWidth: 0, color: 'white'}}
                        onClick={renderProps.onClick}>Logout</Button>}
                    clientId={clientId()}
                    onLogoutSuccess={() => logoutHandler()}
                />
            }
        </Header>
    )
}

export default HeaderComponent
