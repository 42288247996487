import React from 'react'
import PageHeaderComponent from "../../component/page-header.component";
import UserFormComponent from "./user-form.component";

const UserPage = () => {
    return (
        <>
            <PageHeaderComponent title={'User Setting'}/>
            <UserFormComponent />
        </>
    )
}

export default UserPage
