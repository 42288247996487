import React from "react";
import {Button, DatePicker, Row} from "antd";

const {RangePicker} = DatePicker;

const RangePickerComponent = (props) => {
    const {onChangeDatePicker, filterUserListByDate} = props

    return (
        <Row justify="center">
            <div style={{marginRight: 20}}>Filter :</div>
            <RangePicker onChange={onChangeDatePicker}/>
            <Button style={{marginLeft: 10}} type={"primary"}
                    onClick={() => filterUserListByDate()}>Filter</Button>
        </Row>
    )
}

export default RangePickerComponent
