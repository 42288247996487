import React from "react";
import {Button, Col} from "antd";
import {clientId} from "../../ui-util/general-variable";
import {GoogleLogin} from "react-google-login";
import {userSignInApi} from "../../api/user.api";

const LandingPage = () => {

    const responseGoogle = async (response) => {
        try {
            let requestBody = {
                name: response?.profileObj?.name,
                email: response?.profileObj?.email,
                google_id: response?.profileObj?.googleId,
            }
            const result = await userSignInApi(requestBody)
            localStorage.setItem('TOKEN_AUTH', result.token)
            window.location.reload()
        } catch (e) {
            localStorage.removeItem('TOKEN_AUTH')
            console.log('Error userSignInApi', e)
            alert('Maaf akun anda tidak terdaftar sebagai admin!')
        }
    }

    return (
        <div className="page-header" style={{fontSize: 20}}>
            <Col>
                <div>
                    Mohon Login terlebih dahulu untuk mengakses halaman admin
                </div>
                <div className="center" style={{marginTop: 20}}>
                    <GoogleLogin
                        render={renderProps => <Button onClick={renderProps.onClick}>Login</Button>}
                        clientId={clientId()}
                        onSuccess={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    />
                </div>
            </Col>
        </div>
    )
}

export default LandingPage
