import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";
import {DEFAULT_API_HEADER, DEFAULT_API_HEADER_WITH_TOKEN} from "./api.config";

const generateQRCodeApi = (id) => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `http://localhost:8080/api/v2/generator/${id}`,
            {headers: DEFAULT_API_HEADER_WITH_TOKEN},
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

const getQRFileListApi = (requestBody, params) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/generator/`,
            requestBody,
            {
                headers: DEFAULT_API_HEADER,
                params
            },
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

const updateQrDetailApi = (id) => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/v1/generator/download-pdf/${id}`,
            {headers: DEFAULT_API_HEADER},
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}

const requestQrApi = (requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v2/generator/request`,
            requestBody,
            {headers: DEFAULT_API_HEADER_WITH_TOKEN},
        ).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    }));
}

const getQrRequestedApi = (params) => {
    return new Promise(((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/v2/generator`,
            {headers: DEFAULT_API_HEADER, params},
        ).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err);
        });
    }));
}


const updateQrApi = (id, requestBody) => {
    return new Promise(((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v2/generator/update/${id}`,
            requestBody,
            {headers: DEFAULT_API_HEADER_WITH_TOKEN},
        ).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    }));
}

export {
    generateQRCodeApi,
    getQRFileListApi,
    updateQrDetailApi,
    requestQrApi,
    getQrRequestedApi,
    updateQrApi
}
