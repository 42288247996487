import React, {useEffect, useState} from "react";
import PageHeaderComponent from "../../component/page-header.component";
import ImageListComponent from "../../component/image-list.component";
import {Button, Image, Row} from "antd";
import EditFormModal from "../../component/edit-form.modal";
import StandardAlertModal from "../../component/standard-alert.modal";
import {getProductListApi, updateProductDetailApi} from "../../api/product.api";
import ProductFormComponent from "../create-product/product-form.component";
import SearchComponent from "../../component/search.component";
import SelectBrandComponent from "../../component/select-brand.component";
import {logoutHandler} from "../../ui-util/general-variable";

const ProductPage = () => {
    const [editModalVisibility, setEditModalVisibility] = useState(false)
    const [deleteModalVisibility, setDeleteModalVisibility] = useState(false)
    const [productDetail, setProductDetail] = useState({})
    const [dataSource, setDataSource] = useState([])
    const [reload, setReload] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [brand, setBrand] = useState('')
    const [size, setSize] = useState(10)
    const [page, setPage] = useState(1)
    const [totalData, setTotalData] = useState(0)
    const [productName, setProductName] = useState('')
    const [columns] = useState([
        {
            title: 'Brand Name', key: 'brandName', dataIndex: 'brand',
            render: (brand) => <div>{brand?.brand_name}</div>
        },
        {title: 'Product Name', dataIndex: 'product_name', key: 'product_name'},
        {title: 'Product Code', dataIndex: 'product_code', key: 'product_code'},
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image) => <Image src={image} width={100} height={100}/>
        }, {
            title: 'Action', key: 'edit',
            render: (record) =>
                <>
                    <Button style={{marginRight: 10}}
                            onClick={() => {
                                setEditModalVisibility(true)
                                setProductDetail(record)
                            }}>Edit</Button>
                    <Button
                        onClick={() => {
                            setDeleteModalVisibility(true)
                            setProductDetail(record)
                        }}>Delete</Button>
                </>
        }
    ])

    useEffect(() => {
        const getProductList = async () => {
            setLoadingTable(true)
            let requestBody = {
                name: productName,
                brandId: brand
            }
            let params = {
                page: page - 1,
                size
            }
            try {
                const result = await getProductListApi(requestBody, params)
                setTotalData(result.totalDatas)
                setDataSource(result.data)
                setLoadingTable(false)
            } catch (e) {
                console.log('Error getProductListApi', e)
                setLoadingTable(false)
            }
        }

        getProductList()
    }, [reload, size, page, productName, brand])

    const onFinish = (values, fileImage) => {
        if (fileImage.length < 1 && !!values.image.file) {
            alert('Anda belum mencantumkan image')
        } else {
            setLoading(true)
            const form = new FormData();
            form.append('image', fileImage[0])
            form.append('name', values.name)
            form.append('code', values.code)
            updateProductDetail(form, 'update')
        }
    }

    const onOkButtonHandler = () => {
        const form = new FormData();
        form.append('valid', false)
        updateProductDetail(form, 'delete')
    }

    const updateProductDetail = async (form, slug) => {
        try {
            const response = await updateProductDetailApi(productDetail.id, form)
            if(response.data.code === 402){
                logoutHandler()
            } else {
                setReload(!reload)
                setLoading(false)
                if(slug === 'update'){
                    setEditModalVisibility(false)
                } else {
                    setDeleteModalVisibility(false)
                }
                let alertMessage = slug === 'update' ? 'Product berhasil di ubah!' : 'Berhasil menghapus product!'
                alert(alertMessage)
            }
        } catch (e) {
            console.log('Error updateBrandDetailApi', e)
            setLoading(false)
        }
    }

    const onChangePagination = (currentPage) => {
        setPage(currentPage)
    }

    const onSizeChange = (currentPage, pageSize) => {
        setPage(currentPage)
        setSize(pageSize)
    }

    return (
        <>
            <PageHeaderComponent title={'Product list'}/>
            <Row style={{marginLeft: 20, marginBottom: 30}} justify="space-between">
                <SearchComponent placeholder={"Input product name"} onSearch={(value) => setProductName(value)}/>
                <SelectBrandComponent onSelect={(value) => setBrand(value)}/>
            </Row>
            <ImageListComponent
                columns={columns}
                dataSource={dataSource}
                loading={loadingTable}
                pagination={{
                    pageSize: size,
                    pageSizeOptions: ['25', '50', '100'],
                    total: totalData,
                    onChange: onChangePagination,
                    current: page,
                    onShowSizeChange: onSizeChange,
                }}
            />
            <EditFormModal
                modalVisibility={editModalVisibility}
                changeVisibility={() => setEditModalVisibility(false)}
                title={"Edit product"}
                content={
                    <ProductFormComponent
                        wrapperCol={20}
                        labelCol={4}
                        productDetail={productDetail}
                        loading={loading}
                        onFinish={onFinish}
                        slug={'edit'}
                    />}
            />
            <StandardAlertModal
                modalVisibility={deleteModalVisibility}
                title={"Delete product"}
                changeVisibility={() => setDeleteModalVisibility(false)}
                bodyMessage={"Apakah anda yakin ingin menghapus product ini?"}
                onOkButtonHandler={onOkButtonHandler}
            />
        </>
    )
}

export default ProductPage
