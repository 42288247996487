import React, {useEffect, useState} from "react";
import {Button, Form, Image, Input, List, Row, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {randomString} from "../../ui-util/general-variable";

const BrandFormComponent = (props) => {
    const formRef = React.createRef()
    const {onFinish, wrapperCol, labelCol, brandDetail, loading } = props
    const [fileImageList, setFileImageList] = useState([])
    const [showImage, setShowImage] = useState(false)
    const [brandForm] = useState([
        {
            label: 'Brand Name',
            name: 'brand_name',
            type: 'input',
            required: true,
            messageAlert: 'Please input name!',
            placeholder: 'Cth: Lacoco'
        },{
            label: 'Brand Code',
            name: 'brand_code',
            type: 'input',
            required: true,
            messageAlert: 'Please input code!',
            placeholder: 'Cth: LL'
        },{
            label: 'Image',
            name: 'image',
            type: 'image',
            required: false,
            messageAlert: 'Please upload image!',
        },
    ])

    const beforeUploadImage = (fileImage) => {
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            setFileImageList([fileImage])
            setShowImage(false)
        }
        return false
    }

    useEffect(() => {
        if (!!brandDetail) {
            setShowImage(true)
            formRef.current.setFieldsValue(brandDetail)
        } else {
            formRef.current.setFieldsValue({brand_code: randomString(2)})
        }
    }, [brandDetail])

    return (
        <Form ref={formRef} name="control-hooks" onFinish={(value) => onFinish(value, fileImageList)} labelCol={{span: labelCol}}
              wrapperCol={{span: wrapperCol}}>
            <List
                dataSource={brandForm}
                renderItem={(item, index) => {
                    switch (item.type){
                        case 'image':
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={item.label}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Upload
                                        fileList={fileImageList}
                                        onRemove={() => setFileImageList([])}
                                        beforeUpload={beforeUploadImage}
                                    >
                                        {
                                            showImage &&
                                            <Image
                                                src={brandDetail.image}
                                                width={100}
                                            />
                                        }
                                        <Row
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginTop: 10
                                            }}>
                                            <Button icon={<UploadOutlined/>}>
                                                Upload
                                            </Button>
                                            <p style={{marginLeft: 10}}>Ukuran gambar maksimal 2MB</p>
                                        </Row>
                                    </Upload>
                                </Form.Item>

                            )
                        default:
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={item.label}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <Input disabled={item.name === 'brand_code'} placeholder={item.placeholder}/>
                                </Form.Item>
                            )
                    }
                }}
            />
            <Form.Item
                wrapperCol={{
                    span: 2,
                    offset: labelCol,
                }}
            >
                <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default BrandFormComponent
